import React from 'react';
import { css } from '@emotion/core';

// import { Router } from '@reach/router';
// import Profile from 'components/Profile';
// import Intro from 'components/Intro';
// import Home from 'components/Home';
// import Login from 'components/Login';
// import TogethereMapWrapper from 'components/TogethereMapWrapper';
// import PrivateRoute from 'components/PrivateRoute';
// import { notification, Button } from 'antd';
// import { getUser } from 'utils/auth';
import FullWidthBox from 'components/FullWidthBox';
import ExternalLink from 'components/ExternalLink';
const AboutUs = () => {
  return (
    <>
      <FullWidthBox
        css={css`
          margin-top: 50px;
        `}
        sectionwidth="54rem"
      >
        <h1
          css={css`
            display: none;
          `}
        >
          Togethere.is - Über uns
        </h1>
        <h2>Was ist togethere.is?</h2>
        <p>
          Die aktuelle Situation führt dazu, dass unser normales Leben über
          Wochen stillsteht. Der Gang zum Supermarkt wird für viele zum Problem,
          Reisen ist schier unmöglich geworden – alle nicht-funktionalen
          Betriebe verlieren nach und nach ihre Existenzgrundlage, die
          Wirtschaft und das Leben, so wie wir es kannten, kommt immer mehr zum
          Erliegen. Die Corona-Krise stellt jeden einzelnen vor persönliche,
          neue Herausforderungen. Und obwohl Abstand und Isolation das beste
          Mittel aus der Krise zu sein scheinen, gibt es zunehmend Aufgaben, die
          man alleine nicht länger bewältigen kann. Togethere.is soll genau
          dafür ein Forum bieten und Menschen digital zusammenbringen.
          Diejenigen, die auf Hilfe angewiesen sind – und diejenigen, die Zeit
          und Kraft haben, Hilfe zu leisten. Lokalbasiert – damit die Wege kurz
          und der Nutzen andauernd sind.
        </p>
        <p>Warum wir together.is initiiert haben und wohin wir damit wollen?</p>

        <p>
          #togethereisfuture
          <br />
          Unser Ziel ist es aus der Not eine Tugend zu machen und diese
          Mentalität mit aus der Krise zu nehmen - für eine nachhaltigere und
          hoffentlich sogar fairere Arbeitswelt. Die Zusammenarbeit mit lokalen
          Partnern senkt am Ende nicht nur die Kosten, da lange Anreisen
          wegfallen, sondern entlastet auch unsere Umwelt.   
        </p>
        <p>
          #togethereiseveryone
          <br />
          Wir haben einen Discordchanel eröffnet, um die Seite und das Konzept
          dahinter ständig zu verbessern und genau so flexibel reagieren zu
          können, wie es die Situation erforderlich macht. Die Corona-Krise
          betrifft jeden einzelnen auf sehr persönliche Art – daher möchten wir
          auch eure ganz individuellen Herausforderungen, Sichtweisen und
          Lösungsansätze miteinfließen lassen, damit togethere.is zu einer
          Plattform wird, die nicht nur viele verbindet, sondern am Ende vor
          allem allen nutzt.
        </p>
        <p>
          #togethereissolidaric
          <br />
          Wer arbeiten geht und sich dem Risiko einer Ansteckung aussetzt,
          verpflichtet sich eigenverantwortlich, die Schwachen unserer
          Gesellschaft zu schützen. Keinerlei Kontakt nach einem Dreh über einen
          Zeitraum von mindestens 2 Wochen. Dieses Verhalten gilt ausnahmslos
          berufsübergreifen. Nur wenn Solidarität ansteckend ist, ist es das
          Virus nicht.
        </p>
      </FullWidthBox>
      <FullWidthBox sectionwidth="54rem">
        <h2>Wer sind wir</h2>
        <h3>Pfefferminz Film GmbH</h3>
        <p>
          Pfefferminz ist jung und bringt frischen Wind in die Branche. Vorbei
          sind die Zeiten, in denen wir noch an den großen Sets in München mit
          großem Budget arbeiten konnten. Im Zuge der Digitalisierung steigt vor
          allem die Nachfrage an qualitativ hochwertigem und trotzdem
          bezahlbarem Content.
        </p>
        <h4>Kontakt</h4>
        PFEFFERMINZ FILM GMBH
        <br />
        Höhenweg 4A, 87435 Kempten
        <br />
        <ExternalLink href="mailto:info@pfefferminzmich.de">
          info@pfefferminzmich.de
        </ExternalLink>
        <br />
        <ExternalLink href="tel:+491605859235">+49 160 58 59 235</ExternalLink>
        <br />
        <ExternalLink href="tel:+491736872323">+49 173 687 23 23</ExternalLink>
        <br />
      </FullWidthBox>
      <FullWidthBox sectionwidth="54rem">
        <h3>Unsere Unterstützer</h3>
        <h4>marc radziwill.</h4>
        <p>
          Developer Advocate, Web Entwickler, Frontend Architekt, Entwickler
          Trainer
        </p>
        marc radziwill.
        <br />
        Ostbahnhofstraße 7, 87437 Kempten
        <br />
        <ExternalLink href="mailto:hallo@marcradziwill.com">
          hallo@marcradziwill.com
        </ExternalLink>
        <br />
        <ExternalLink target="_blank" href="https://marcradziwill.com">
          marcradziwill.com
        </ExternalLink>
        <br />
        <br />
        <h4>Isenhoffs Büro</h4>
        <p>Kreativagentur mit Herz, Berg & Verstand aus Kempten im Allgäu.</p>
        Anke Isenberg de Abreu, Philip Herzhoff, Thomas Herzhoff
        <br />
        Höhenweg 4a 87437 Kempten
        <br />
        <ExternalLink href="mailto:contact@isenhoff.de">
          contact@isenhoff.de
        </ExternalLink>
        <br />
        <ExternalLink target="_blank" href="https://isenhoff.de">
          isenhoff.de
        </ExternalLink>
        <br />
        <ExternalLink href="tel:+49831930630780">
          +49 (0)831 930 630 780
        </ExternalLink>
        <br />
      </FullWidthBox>
    </>
  );
};
export default AboutUs;
